import React from "react"
import Helmet from "react-helmet"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Jumbotron,
} from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faCircle } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"

import ImageModal from "../components/image-modal"
import VideoModal from "../components/video-modal"
import AudioModal from "../components/audio-modal"

import "bootstrap/dist/css/bootstrap.min.css"
import "@fortawesome/fontawesome-svg-core/styles.css"
import "../styles/main.css"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    easing: "easeInOutCubic",
    offset: 60,
  })
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  componentDidMount() {
    function addClass() {
      document.querySelector("header").classList.add("scrolled")
    }
    function removeClass() {
      document.querySelector("header").classList.remove("scrolled")
    }
    window.addEventListener("scroll", function() {
      if (window.scrollY > 90) {
        addClass()
      } else {
        removeClass()
      }
    })
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Paul Cyronek</title>
          <link rel="canonical" href="https://paulcyronek.de/" />
          <meta name="robots" content="index, follow" />
          <meta
            name="description"
            content="Mediengestalter für Digital- und Printmedien"
          />
        </Helmet>
        {/**
        HEADER
        */}
        <header>
          <Navbar expand="lg" fixed="top">
            <Container>
              <NavbarBrand
                className="font-weight-light text-uppercase text-white"
                href="#top"
              >
                Paul Cyronek
              </NavbarBrand>
              <button
                aria-label="Toggle navigation"
                type="button"
                className="border-0 navbar-toggler p-0"
                onClick={this.toggle}
              >
                <FontAwesomeIcon icon={faBars} size="2x" />
              </button>
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto text-right" navbar>
                  <NavItem>
                    <NavLink
                      className="text-uppercase d-block d-lg-none py-2"
                      href="#portfolio"
                      onClick={this.toggle}
                    >
                      Portfolio
                    </NavLink>
                    <NavLink
                      className="text-uppercase d-none d-lg-block"
                      href="#portfolio"
                    >
                      Portfolio
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="text-uppercase d-block d-lg-none py-2"
                      href="#about"
                      onClick={this.toggle}
                    >
                      Über Mich
                    </NavLink>
                    <NavLink
                      className="text-uppercase d-none d-lg-block"
                      href="#about"
                    >
                      Über Mich
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
          <div className="banner position-absolute h-100 w-100">
            <Container className="d-flex flex-column justify-content-center position-relative h-100">
              <h1 className="text-uppercase text-white pt-5 mt-4 mr-5">
                Paul Cyronek
              </h1>
              <h2 className="font-weight-light text-uppercase">
                Mediengestalter für Digital- und Printmedien
              </h2>
              <div className="social-media my-2">
                <a
                  href="https://facebook.com/paulcyronek"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    mask={faCircle}
                    size="lg"
                    transform="shrink-6"
                  />
                </a>
                <a
                  className="px-1 mx-3"
                  href="https://instagram.com/paulcyronek"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    mask={faCircle}
                    size="lg"
                    transform="shrink-6"
                  />
                </a>
                <a
                  href="https://de.linkedin.com/in/paul-cyronek-4b81a1156"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    mask={faCircle}
                    size="lg"
                    transform="shrink-6"
                  />
                </a>
              </div>
            </Container>
          </div>
          <Jumbotron className="m-0 p-0" fluid>
            <BackgroundImage
              fluid={this.props.data.header.childImageSharp.fluid}
              backgroundColor={`#000`}
            />
            <video
              className="d-none d-lg-block"
              poster="/video/poster.jpg"
              autoPlay
              loop
              muted
            >
              <source src="/video/paulcyronek.mp4" type="video/mp4" />
              <source src="/video/paulcyronek.webm" type="video/webm" />
              <source src="/video/paulcyronek.ogv" type="video/ogg" />
            </video>
            <div className="overlay position-absolute h-100 w-100"></div>
          </Jumbotron>
        </header>
        {/**
        MAIN
        */}
        <main>
          <section id="portfolio">
            <Container className="pt-3 pb-1 pt-lg-4 pb-lg-0">
              {/** KNAUER */}
              <Row className="justify-content-between pt-3 pb-1 pt-lg-4 pb-lg-5">
                <Col lg="6">
                  <VideoModal
                    className="knauer-modal"
                    video="https://www.youtube-nocookie.com/embed/GlKtHKVP57I"
                    image={this.props.data.knauer.childImageSharp.fluid}
                    heading="Knauer"
                    period="seit 2017"
                    paragraph1="Hightech Labormessgeräte, Berlin"
                    paragraph2="Grafik, Foto, Video"
                  />
                </Col>
                <Col lg="6" className="d-flex align-items-center">
                  <div className="pt-4 pb-3 pl-lg-5">
                    <h3 className="font-weight-bold text-uppercase mb-2">
                      Knauer
                    </h3>
                    <p className="font-weight-bold text-uppercase mb-2 period">
                      seit 2017
                    </p>
                    <p className="font-weight-light mb-2">
                      Hightech Labormessgeräte, Berlin
                    </p>
                    <p className="font-weight-light mb-2">
                      Grafik, Foto, Video
                    </p>
                  </div>
                </Col>
              </Row>
              {/** KREIS UNNA */}
              <Row className="justify-content-between pt-3 pb-1 pt-lg-4 pb-lg-5">
                <Col lg="6">
                  <ImageModal
                    className="kreis-unna-modal"
                    image={this.props.data.kreisUnna.childImageSharp.fluid}
                    heading="Kreis Unna"
                    period="2008 – 2017"
                    paragraph1="Presse und Kommunikation"
                    paragraph2="Online, Social Media, Öffentlichkeitsarbeit"
                  />
                </Col>
                <Col lg="6" className="d-flex align-items-center">
                  <div className="pt-4 pb-3 pl-lg-5">
                    <h3 className="font-weight-bold text-uppercase mb-2">
                      Kreis Unna
                    </h3>
                    <p className="font-weight-bold text-uppercase mb-2 period">
                      2008 – 2017
                    </p>
                    <p className="font-weight-light mb-2">
                      Presse und Kommunikation
                    </p>
                    <p className="font-weight-light mb-2">
                      Online, Social Media, Öffentlichkeitsarbeit
                    </p>
                  </div>
                </Col>
              </Row>
              {/** POTTBURGER */}
              <Row className="justify-content-between pt-3 pb-1 pt-lg-4 pb-lg-5">
                <Col lg="6">
                  <VideoModal
                    className="pottburger-modal"
                    video="https://player.vimeo.com/video/197795072"
                    image={this.props.data.pottburger.childImageSharp.fluid}
                    heading="Pottburger"
                    period="2015 – 2016"
                    paragraph1="Burgerrestaurant, Dortmund"
                    paragraph2="Videos, Social Media"
                  />
                </Col>
                <Col lg="6" className="d-flex align-items-center">
                  <div className="pt-4 pb-3 pl-lg-5">
                    <h3 className="font-weight-bold text-uppercase mb-2">
                      Pottburger
                    </h3>
                    <p className="font-weight-bold text-uppercase mb-2 period">
                      2015 – 2016
                    </p>
                    <p className="font-weight-light mb-2">
                      Burgerrestaurant, Dortmund
                    </p>
                    <p className="font-weight-light mb-2">
                      Videos, Social Media
                    </p>
                  </div>
                </Col>
              </Row>
              {/** ENTSCHLEUNIGER */}
              <Row className="justify-content-between pt-3 pb-1 pt-lg-4 pb-lg-5">
                <Col lg="6">
                  <AudioModal
                    className="entschleuniger-modal"
                    audio="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/304603605&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                    image={this.props.data.entschleuniger.childImageSharp.fluid}
                    heading="Entschleuniger"
                    period="2016"
                    paragraph1="Baukunstarchiv NRW, Dortmund"
                    paragraph2='Ausstellung "Zeitlupe", Interaktive Klanginstallation'
                  />
                </Col>
                <Col lg="6" className="d-flex align-items-center">
                  <div className="pt-4 pb-3 pl-lg-5">
                    <h3 className="font-weight-bold text-uppercase mb-2">
                      Entschleuniger
                    </h3>
                    <p className="font-weight-bold text-uppercase mb-2 period">
                      2016
                    </p>
                    <p className="font-weight-light mb-2">
                      Baukunstarchiv NRW, Dortmund
                    </p>
                    <p className="font-weight-light mb-2">
                      Ausstellung "Zeitlupe", Interaktive Klanginstallation
                    </p>
                  </div>
                </Col>
              </Row>
              {/** BOS BBQ */}
              <Row className="justify-content-between pt-3 pb-1 pt-lg-4 pb-lg-5">
                <Col lg="6">
                  <ImageModal
                    className="bos-bbq-modal"
                    image={this.props.data.bosBBQ.childImageSharp.fluid}
                    heading="Bo's BBQ"
                    period="2013 – 2014"
                    paragraph1="Catering Service, Dortmund"
                    paragraph2="Logo, Geschäftsausstattung, Social Media"
                  />
                </Col>
                <Col lg="6" className="d-flex align-items-center">
                  <div className="pt-4 pb-3 pl-lg-5">
                    <h3 className="font-weight-bold text-uppercase mb-2">
                      Bo's BBQ
                    </h3>
                    <p className="font-weight-bold text-uppercase mb-2 period">
                      2013 – 2014
                    </p>
                    <p className="font-weight-light mb-2">
                      Catering Service, Dortmund
                    </p>
                    <p className="font-weight-light mb-2">
                      Logo, Geschäftsausstattung, Social Media
                    </p>
                  </div>
                </Col>
              </Row>
              {/** BOOK LOUNGE */}
              <Row className="justify-content-between pt-3 pb-1 pt-lg-4 pb-lg-5">
                <Col lg="6">
                  <ImageModal
                    className="book-lounge-modal"
                    image={this.props.data.bookLounge.childImageSharp.fluid}
                    heading="Book Lounge"
                    period="2011"
                    paragraph1="eBook Cafe, Bochum"
                    paragraph2="Logo, Geschäftsausstattung"
                  />
                </Col>
                <Col lg="6" className="d-flex align-items-center">
                  <div className="pt-4 pb-3 pl-lg-5">
                    <h3 className="font-weight-bold text-uppercase mb-2">
                      Book Lounge
                    </h3>
                    <p className="font-weight-bold text-uppercase mb-2 period">
                      2011
                    </p>
                    <p className="font-weight-light mb-2">eBook Cafe, Bochum</p>
                    <p className="font-weight-light mb-2">
                      Logo, Geschäftsausstattung
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section id="about">
            <Container>
              <Row className="justify-content-between">
                <Col lg="5" className="d-flex align-items-center">
                  <div className="py-4">
                    <h3 className="font-weight-bold text-uppercase mb-2">
                      Über mich
                    </h3>
                    <p className="font-weight-light mb-2">
                      Als Kind der Neunziger entwickelte ich schon in jungen
                      Jahren eine unbändige Liebe zu Medien und Werbung. Der
                      Ideenreichtum, aber auch die Umsetzung begeisterten mich,
                      sodass entsprechend früh klar war: "Irgendwas mit Medien"
                      werde ich mal beruflich machen.
                    </p>
                    <p className="font-weight-light">
                      Heutzutage gestalte ich Print, Web, Social Media, Video
                      oder sogar Klang: Idealerweise in Kombination!
                    </p>
                    <a
                      href="mailto:mail@paulcyronek.de"
                      className="btn position-relative border-0 text-white px-3 py-1 mt-4"
                    >
                      mail@paulcyronek.de
                    </a>
                  </div>
                </Col>
                <Col lg={{ size: 6, offset: 1 }}>
                  <Img
                    className="d-block d-lg-none mx-auto"
                    fluid={this.props.data.paulXS.childImageSharp.fluid}
                    alt="Paul Cyronek"
                  />
                  <Img
                    className="d-none d-lg-block mx-auto"
                    fluid={this.props.data.paulLG.childImageSharp.fluid}
                    alt="Paul Cyronek"
                  />
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        {/**
        FOOTER
        */}
        <footer>
          <Container>
            <Row>
              <Col>
                <div className="text-right py-3">
                  <div className="social-media py-2 mb-1">
                    <a
                      className="mr-1"
                      href="https://facebook.com/paulcyronek"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        mask={faCircle}
                        size="2x"
                        transform="shrink-6"
                      />
                    </a>
                    <a
                      className="ml-2 mr-1"
                      href="https://instagram.com/paulcyronek"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        mask={faCircle}
                        size="2x"
                        transform="shrink-6"
                      />
                    </a>
                    <a
                      className="ml-2"
                      href="https://de.linkedin.com/in/paul-cyronek-4b81a1156"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faLinkedinIn}
                        mask={faCircle}
                        size="2x"
                        transform="shrink-6"
                      />
                    </a>
                  </div>
                  <p className="font-weight-light mb-1">
                    Copyright &copy;{" "}
                    <span id="update-year">{new Date().getFullYear()}</span>,
                    Paul Cyronek
                  </p>
                  <p className="mb-1">
                    <Link className="text-reset" to="/impressum">
                      Impressum
                    </Link>
                    <span className="font-weight-light"> | </span>
                    <Link className="text-reset" to="/datenschutz">
                      Datenschutz
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    )
  }
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    knauer: file(relativePath: { eq: "knauer.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kreisUnna: file(relativePath: { eq: "kreis-unna.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pottburger: file(relativePath: { eq: "pottburger.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    entschleuniger: file(relativePath: { eq: "entschleuniger.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bosBBQ: file(relativePath: { eq: "bos-bbq.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bookLounge: file(relativePath: { eq: "book-lounge.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paulXS: file(relativePath: { eq: "paul-xs.png" }) {
      childImageSharp {
        fluid(maxWidth: 510, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paulLG: file(relativePath: { eq: "paul-lg.png" }) {
      childImageSharp {
        fluid(maxWidth: 510, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
