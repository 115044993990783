import React, { useState } from "react"
import Img from "gatsby-image"

import { Container, Button, Modal, ModalHeader, ModalBody } from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const VideoModal = props => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const closeBtn = (
    <button className="close m-0 p-0" onClick={toggle}>
      <FontAwesomeIcon icon={faTimes} size="lg" />
    </button>
  )
  return (
    <div>
      <Button className="border-0 w-100 p-0" onClick={toggle}>
        <Img
          imgStyle={{ width: "100%", height: "auto" }}
          fluid={props.image}
          alt={props.heading}
        />
      </Button>
      <Modal
        className={`w-100 h-100 mw-100 m-0 p-0 ${props.className}`}
        isOpen={modal}
        toggle={toggle}
        backdrop={false}
      >
        <Container>
          <ModalHeader
            className="border-0 p-0"
            toggle={toggle}
            close={closeBtn}
          />
          <ModalBody className="p-0">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                width="640"
                height="360"
                frameBorder="0"
                allowFullScreen
                src={props.video}
                title={props.className}
              ></iframe>
            </div>
            <div className="my-4">
              <h3 className="font-weight-bold text-uppercase mb-2 pt-1">
                {props.heading}
              </h3>
              <p className="font-weight-bold text-uppercase mb-2 period">
                {props.period}
              </p>
              <p className="font-weight-light mb-2">{props.paragraph1}</p>
              <p className="font-weight-light mb-2">{props.paragraph2}</p>
            </div>
          </ModalBody>
        </Container>
      </Modal>
    </div>
  )
}

export default VideoModal
